import {
    AppMeta,
    FeatureFlagsData
} from '../../../../../common/utils/hydration';


export const getDeviceAndMembershipQuickViewEP = (featureFlagsData?: FeatureFlagsData, appMeta?: AppMeta): boolean | undefined => {
    const { isGuest = false, isMobile = false } = appMeta || {};
    if (isGuest) {
        return isMobile
            ? featureFlagsData?.enableQuickViewMobileGuest
            : featureFlagsData?.enableQuickViewDesktopGuest;
    }
    return isMobile
        ? featureFlagsData?.enableQuickViewMobileMember
        : featureFlagsData?.enableQuickViewDesktopMember;
};
